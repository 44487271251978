import { createAction, createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

const defaultState = {
    isLoggedIn: false,
};

export default objectDuckCreator({
    namespace: '',
    store: 'auth',
    initialState: {
        ...defaultState,
    },
}).extend({
    creators: (duck) => ({
        setLoggedIn: createAction(duck.types.SET_LOGGED_IN),
        setLoggedOut: createAction(duck.types.SET_LOGGED_OUT),
    }),
    selectors: () => ({
        isLoggedIn: new DuckSelector((selector) => createSelector(
            selector.getDuckState,
            (duckState) => duckState.isLoggedIn
        )),
    }),
    reducer: (state, action, { types }) => {
        switch (action.type) {
            case types.SET_LOGGED_IN: {
                return {
                    ...state,
                    ...defaultState,
                    isLoggedIn: true,
                };
            }
            case types.SET_LOGGED_OUT: {
                return {
                    ...state,
                    ...defaultState,
                };
            }
            default:
                return state;
        }
    },
    types: [
        'SET_LOGGED_IN',
        'SET_LOGGED_OUT',
    ],
});
