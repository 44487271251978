import { fetchJSON } from '@bonnet/core/fetch';
import { get as _get } from '../utilities/common';

export default async function fetchLscOwner(dealerId, zipCode) {
    let lscOwner = {};
    try {
        if (dealerId) {
            const response = await fetchJSON('/rest/lsc/v1/owner', {
                search: { dealerId, zip: zipCode },
                circuitBreaker: {
                    timeout: 3000,
                    fallback: {},
                    id: 'lsc_owner',
                },
            });
            lscOwner = _get(response, 'owners.0', {});
        }
    } catch (error) {
        console.log(error);
    }
    return lscOwner;
}
