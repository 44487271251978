/* eslint-disable linebreak-style */
import { fetchExcludedMakes } from '../fetchers';
import { fetchWithCache } from '.';

export default async function excludeMakes(isSeoMakeATC, makes) {
    const exMakes = await fetchWithCache(
        fetchExcludedMakes,
        null,
        's3-exclude-makes',
        3600000 // 1 hour
    );
    let exMakeCode = exMakes?.makes;
    exMakeCode = exMakeCode?.map((exmake) => exmake.toLowerCase());
    if (isSeoMakeATC) {
        return makes.filter((make) => !exMakeCode?.includes(make.name?.toLowerCase()));
    }
    return makes.filter((make) => !exMakeCode?.includes(make.makeName?.toLowerCase()));
}
