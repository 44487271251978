// convert string from snake_case to camelCase
const snakeToCamel = (str = '') => str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
);

// duplicates snakecase keyed properties with camelCase keyed properties
const duplicatePropertiesWithCamelCase = (acc, [key, value]) => {
    acc[key] = value;

    if (key.includes('-') || key.includes('_')) {
        acc[snakeToCamel(key)] = value;
    }

    return acc;
};

// adds useFeatures functionality to the ctx object
export default function withUseFeatures() {
    return async (ctx) => {
        // looks up singular feature key
        ctx.useFeature = (key) => {

            // currently requires _app to have returned featureFlags from getInitialProps to work
            const { featuresConfig = {} } = ctx.data;
            const { features = {} } = featuresConfig;

            // default feature to NOT enabled
            let isFeatureEnabled = false;
            let featureVariables = {};

            if (features[key]) {
                // if key existed, get it
                const { enabled: configEnabled, variables: configVariables = {} } = features[key];
                isFeatureEnabled = configEnabled;
                featureVariables = configVariables;
            }

            // duplicate snake_case property keys
            featureVariables = Object.entries(featureVariables).reduce(duplicatePropertiesWithCamelCase, {});

            return [isFeatureEnabled, featureVariables];
        };
    };
}
