import { pageNames } from '@atc/bonnet-paths';
import isValidQueryParams from './isValidQueryParams';

const pattern = [
    /(dealers\/new(?:\/|\?|$))/im,
    /(dealers\/used(?:\/|\?|$))/im,
    /(dealers\/third-party-certified(?:\/|\?|$))/im,
    /(dealers\/all(?:\/|\?|$))/im,
    /(dealers\/certified(?:\/|\?|$))/im,
];

const isNoIndex = ({ pageName, canonical, isKbb, hasDealers = false, hasInventory = false, queryParams = {} }) => {
    if (!isValidQueryParams(queryParams)) {
        return true;
    }

    if (pageName === pageNames.DEALER_SEARCH) {
        return !hasDealers || (canonical && pattern.some((reg) => reg.test(canonical)));
    }

    if (pageName === pageNames.DEALER_DETAILS) {
        return !isKbb && !hasInventory;
    }

    return false;
};

export default isNoIndex;
