import { get as _get } from '../utilities/common';
import { getReference } from '@atc/bonnet-reference';
import { getPath, filterSeoMakes, filterDealerQuery, excludeMakes } from '../utilities';
import { kbbBrand, dealerlistQuery } from '../constants';
import getValidSeoMakesFromSiteMap from '../utilities/getValidSeoMakesFromSiteMap';

export default function getSeoMakes(state) {
    return async (ctx) => {
        const makeCode = [].concat(_get(ctx.query, 'makeCode', []));
        const hasMakeCode = makeCode.length > 0;
        const isKbb = (ctx.data.brand === kbbBrand);

        // remove unwanted parameters from the existing query string
        // eslint-disable-next-line no-unused-vars
        const { numRecords, firstRecord, sortBy, dma, searchRadius, location, ...query } = ctx.query;

        if (hasMakeCode && !isKbb) {
            // If there is at least one make code selected then we shouldn't create link data
            ctx.data.seoMakes = [];
        } else {
            const { payload: makes = {} } = await getReference('makeCode');
            const makesFilteredAtc = await excludeMakes(true, makes);
            const seoMakeFiltered = filterSeoMakes(isKbb, makesFilteredAtc, ctx.data.restrictedSeoMakes);

            let data = await Promise.all(Object.keys(seoMakeFiltered).map(async (make) => {
                // eslint-disable-next-line prefer-const
                let { name: label, code } = seoMakeFiltered[make];

                // Build a query object for the new url
                const _query = {
                    ...query,
                    makeCode: code,
                };

                // handle any legacy query key
                delete _query.makeCodeList;
                delete _query.zip;
                delete _query.city;
                delete _query.state;

                const href = await getPath(ctx.pageName, filterDealerQuery(_query, dealerlistQuery), { brand: ctx.data.brand });
                return { href, label };
            }));

            // update the context data object with the response
            const seoMakesFromSiteMap = await getValidSeoMakesFromSiteMap();
            data = data.filter((d) => seoMakesFromSiteMap.includes(d.label.toLowerCase()));
            ctx.data.seoMakes = data;
        }
    };
}
