import { get as _get } from '../utilities/common';
import getConfig from 'next/config';
import { fetchKbbRating, fetchOwnersFromBYC } from '../fetchers';
import { recentSearchDuck } from '../ducks';
import { allMakeValue, phoneNumberLabel } from '../constants/index';
import { enableKBBSeoMakes } from '../utilities';

const isNumber = (value) => Number.isNaN(Number(value));

const mapDealerObject = (item, i) => ({
    distanceFromSearch: item.Distance,
    // the VendorDealerIds in byc dealer list maybe be duplicated but the dealer card component is identify by id,
    // so maybe we add the makeId to id to work well with dealer card component
    id: ((item.PartnerFranchiseId !== null && item.PartnerFranchiseId.length > 0) && !isNumber(item.PartnerFranchiseId))
        ? parseFloat(item.PartnerFranchiseId) + item.MakeId + i
        : `isNull${i}`,
    name: item.FranchiseName,
    franchiseName: item.FranchiseName,
    ownerTitleCTA: item.FranchiseAndMake,
    location: {
        address: {
            address1: item.FranchiseAddress1,
            city: item.FranchiseCity,
            state: item.FranchiseState,
            zip: item.FranchiseZip,
        },
        map: {
            href: null,
        },
    },
    phone: {
        privateNumber: false,
        label: phoneNumberLabel,
        value: item.FranchisePhone ? item.FranchisePhone.replace(/-/g, '') : '',
        visible: true,
    },
    primaryPhotoUrl: item.PrimaryPhoto,
    storefrontWebURL: item.StorefrontWebURL,
    rating: item.rating,
    isShowCase: item.HasShowcaseTreatment,
    hasDealerDetail: item.LeadSource === 'LSC' ? item.PartnerFranchiseId != null : item.OwnerProduct !== null,
    website: {
        href: `?manufacturername=${item.MakeName}&franchisekey=${(item.FranchiseId * 1000) + item.MakeId}`,
    },
    makeId: item.MakeId,
    makeName: item.MakeName,
    dealerId: item.PartnerFranchiseId,
    isPayingDealer: item.IsServiceDealer,
});

const getBycOwners = async (dealerQuery, query) => {
    let bycOwners = {
        owners: null,
        totalResultCount: null,
    };
    const { publicRuntimeConfig } = getConfig();
    dealerQuery.makeCode = (query.make === allMakeValue) ? '' : query.make;

    const bycResponses = await fetchOwnersFromBYC(dealerQuery);
    if (!bycResponses) {
        return {
            owners: [],
            totalResultCount: 0,
        };
    }

    // Batch fetch KBB ratings for multiple dealers
    const fetchBatchKbbRatings = async (dealers) => {
        const validDealers = dealers.filter((dealer) => dealer.VendorDealerId); // Filter dealers with VendorDealerId

        // Batch fetch all ratings at once
        const kbbRatings = await Promise.all(
            validDealers.map((dealer) => fetchKbbRating(dealer.VendorDealerId))
        );

        // Create a map of VendorDealerId to rating
        const ratingsMap = new Map(
            kbbRatings.map((rating, index) => [
                validDealers[index].VendorDealerId,
                rating,
            ]).filter(([, rating]) => rating !== null)
        );

        return ratingsMap;
    };

    // Process all dealers with batched KBB ratings
    const processAllDealers = async () => {
        const kbbRatingsMap = await fetchBatchKbbRatings(bycResponses); // Fetch all KBB ratings at once

        return Promise.all(bycResponses.map((dealer, index) => {
            if (dealer.VendorDealerId && kbbRatingsMap.has(dealer.VendorDealerId)) {
                const kbbRating = kbbRatingsMap.get(dealer.VendorDealerId);
                dealer = {
                    ...dealer,
                    rating: (kbbRating.count === 0 || kbbRating.kbbDealerRating < 1) ? null : {
                        count: kbbRating.count,
                        value: kbbRating.kbbDealerRating,
                    },
                };
            }

            const enrichedDealer = mapDealerObject(dealer, index);

            if (enrichedDealer.IsServiceDealer) {
                enrichedDealer.isPayingDealer = true;
            }

            enrichedDealer.website.href = `${publicRuntimeConfig.fdpqAppUrl}${enrichedDealer.website.href}`;
            if (query.psid) {
                enrichedDealer.website.href += `&psid=${query.psid}`;
            }

            return enrichedDealer;
        }));
    };

    const dealers = await processAllDealers();

    bycOwners = {
        owners: dealers.sort((a, b) => (b.isShowCase - a.isShowCase) || (a.distanceFromSearch - b.distanceFromSearch)),
        totalResultCount: dealers.length,
        restrictedSeoMakes: enableKBBSeoMakes(dealers),
    };
    return bycOwners;
};

export default function getOwnersAndFilters() {
    return async (ctx) => {
        const fetchOptions = {};

        if (ctx.req) {
            // add cookie to headers so we don't lose it on the server
            fetchOptions.headers = {
                cookie: ctx.req.headers.cookie,
            };
        }

        // TODO: bonnet-atc - should this be pared down to only the query params we care about?
        // remove location and dma data from the query
        // eslint-disable-next-line no-unused-vars
        const { location = {}, dma, ...query } = ctx.query;

        // add zip to the query if it exists
        if (location.zip) {
            query.zip = location.zip;
        }
        const [, { channel }] = ctx.useFeature('brand');
        query.channel = channel || ctx.data.brand.toUpperCase();

        const dealerQuery = {
            makeCode: query.makeCode,
            modelCode: query.modelCode,
            listingType: query.listingType,
            trimCode: query.trimCode,
            seriesCode: query.seriesCode,
            zip: query.zip,
            channel: query.channel,
        };

        if (!dealerQuery.makeCode && !dealerQuery.listingType) {
            // use recent searches
            const recentSearches = recentSearchDuck.selectors.getRecentSearches(ctx.store.getState());
            if (recentSearches && recentSearches.length > 0) {
                const { makeCodeList, modelCodeList, listingTypes, trimCodeList, seriesCodeList } = _get(recentSearches[0], 'query', {});

                dealerQuery.makeCode = makeCodeList;
                dealerQuery.modelCode = modelCodeList;
                dealerQuery.listingType = listingTypes;
                dealerQuery.trimCode = trimCodeList;
                dealerQuery.seriesCode = seriesCodeList;
            }
        }

        // call out to BYC Owners / dealer list for dealers
        const bycOwners = await getBycOwners(dealerQuery, query);
        Object.assign(ctx.data, { ...bycOwners });

    };
}
