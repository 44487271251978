import { cloneDeep } from './common';
import { pageNames } from '@atc/bonnet-paths';
import { breadcrumbsForFLD, allMakeValue, kbbBrand } from '../constants';
import { getPath } from '.';

const getBreadcrumbsForFLD = async (make, location, brand) => {
    const isKbb = brand === kbbBrand;
    const breadcrumbData = breadcrumbsForFLD(isKbb);
    const breadcrumbsWithMade = cloneDeep(breadcrumbData);
    const { city, state } = location;
    const seoMakeCode = make?.toLowerCase().replace(' ', '-');
    const homePath = await getPath(pageNames.DEALER_SEARCH, {}, { brand });

    breadcrumbsWithMade.find((item) => (item.label === 'Home'))
        .href = homePath;

    // if breadcrumbs has make
    if (make && make !== allMakeValue) {
        let query = { seoMakeCode };
        // if breadcrumbs has make and location
        if (city && state) {
            query = { ...query, city, state };
            delete query.zip;
            const path = await getPath(pageNames.DEALER_SEARCH, query, { brand });
            breadcrumbsWithMade.push({
                label: make,
                href: path,
            });
        } else { // if breadcrumbs has make and empty location
            const path = await getPath(pageNames.DEALER_SEARCH, query, { brand });
            breadcrumbsWithMade.find((item) => (item.label?.toLowerCase().includes('dealers')))
                .href = path;
            breadcrumbsWithMade.push({
                label: make,
                href: '',
            });
        }

    }
    // if breadcrumbs has location
    if (city && state) {
        const query = { city, state };
        const path = await getPath(pageNames.DEALER_SEARCH, query, { brand });
        breadcrumbsWithMade.find((item) => (item.label?.toLowerCase().includes('dealers')))
            .href = path;
        breadcrumbsWithMade.push({
            label: `${city}, ${state}`,
            href: '',
        });
    }
    return breadcrumbsWithMade;
};

export default getBreadcrumbsForFLD;
