/* eslint-disable no-use-before-define */
const merge = (target, ...sources) => {
    if (!isObject(target)) return target;

    sources.forEach((source) => {
        if (!isObject(source)) return;

        Object.keys(source).forEach((key) => {
            const srcValue = source[key];
            const targetValue = target[key];

            if (isObject(srcValue) && isObject(targetValue)) {
                target[key] = merge(targetValue, srcValue);
            } else if (Array.isArray(srcValue) && Array.isArray(targetValue)) {
                target[key] = [...new Set([...targetValue, ...srcValue])];
            } else {
                target[key] = srcValue;
            }
        });
    });

    return target;
};

const isObject = (value) => value && typeof value === 'object' && !Array.isArray(value);

export default merge;
