import React from 'react';
import { useStore } from 'react-redux';
import { BrandProvider } from 'reaxl-brand';
import dynamic from 'next/dynamic';

import { withBonnetApp } from '@bonnet/next/app';
import getConfig from 'next/config';
import atcId from '@atc/bonnet-ctx-atc-id';
import isBot from '@bonnet/ctx-is-bot';
import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';
import { setReduxDefaultMiddlewareOptions } from '@bonnet/next/redux';
import { get as _get } from '../utilities/common';

import {
    OptimizelyPageProvider,
    addOptimizelyDynamicConfig,
} from 'reaxl-optimizely';

import { Provider as FeatureFlagsContextProvider } from 'reaxl-features';
import { HelmetProvider } from 'react-helmet-async';

import { DataIslandProvider } from 'reaxl-context';

// import { calculateResponsiveState } from 'redux-responsive';

import {
    authDuck,
    expiredPageMessageDuck,
    inventoryDuck,
    ownersDuck,
    paymentsDuck,
    dealsDuck,
    userDuck,
    birfDuck,
    currentPageNameDuck,
    globalConfigsDuck,
    savedInventoryDuck,
    recentSearchDuck,
    dealerSetMakeDuck,
    dealerCrawlPathDuck,
    dealerFiltersDuck,
    dealerMetadataDuck,
    dealerPaginationDuck,
    dealerResultsDuck,
    dealerSortDuck,
    dealerLotDuck,
    dealerLotResultsDuck,
    dealerUrlsDuck,
    dealerSetPaidSearchIdDuck,
    inventoryActiveOwnerDuck,
    retainOwnerDuck,
    // dealerdetail Duck
    inventoryActiveInteractionDuck,
    inventoryFiltersDuck,
    inventoryMetadataDuck,
    inventoryPaginationDuck,
    inventoryResultsDuck,
    inventorySortDuck,
    inventoryUrlsDuck,
    inventorySupportMakesDuck,
    dealerSearchPathDuck,
    dealerTabsDuck,
    showContactDealerDuck,
    serviceDealerDuck,
    showInventoryDuck,
    dealerDetailOriginDuck,
    inventoryActiveEmailListingDuck,
    queryDuck,
    lscOwnerDuck,
} from '../ducks';
import { processAuthState, withFeatures, withKbbNavFromS3 } from '../context-middleware';
import SassTheme from '../components/SassTheme';
import withBrand from '@atc/bonnet-ctx-brand';
import brandDuck from '../ducks/brandDuck';
import ThemeProvider from '../containers/ThemeProvider';
import PersonalizationEngineModule from '../modules/PersonalizationEngineModule';

const HeaderContainer = dynamic(() => import('../containers/HeaderContainer'), {});
const FooterContainer = dynamic(() => import('../containers/FooterContainer'), {});

// Disable redux middleware performance checks
setReduxDefaultMiddlewareOptions({
    immutableCheck: false,
    serializableCheck: false,
});

const App = ({ Component, brand, disableThirdparty, optimizelyDynamicConfig, featuresConfig, navS3Data, userwcbranch }) => {
    // support Cypress Redux tests
    const store = useStore();
    if (typeof window !== 'undefined' && window.Cypress) {
        window.store = store;
    }

    return (
        <OptimizelyPageProvider
            disabled={process.env.DISABLE_OPTIMIZELY || disableThirdparty}
            optimizelyDynamicConfig={optimizelyDynamicConfig}
        >
            <FeatureFlagsContextProvider {...featuresConfig}>
                <HelmetProvider context={{}}>
                    <BrandProvider brand={brand}>
                        <DataIslandProvider>
                            {/* <Container className={`app-container ${brand}`}> */}
                            <SassTheme brand={brand} />
                            <ThemeProvider brand={brand}>
                                <HeaderContainer
                                    brand={brand}
                                    navS3Data={navS3Data}
                                    userwcbranch={userwcbranch}
                                />
                                <Component />
                                <FooterContainer
                                    brand={brand}
                                    navS3Data={navS3Data}
                                />
                            </ThemeProvider>
                        </DataIslandProvider>
                    </BrandProvider>
                </HelmetProvider>
            </FeatureFlagsContextProvider>
        </OptimizelyPageProvider>
    );
};

App.getInitialProps = async (appCtx) => {
    const { ctx } = appCtx;
    const { publicRuntimeConfig } = getConfig();
    const { environment } = publicRuntimeConfig;

    // apply context middleware
    await withCtxMiddleware([
        withBrand(),
        withFeatures({ environment }),
        isBot(),
        atcId(),
        addOptimizelyDynamicConfig({ environment }),
        processAuthState(),
        withKbbNavFromS3(),
    ], ctx);

    const disableThirdparty = _get(ctx, 'query.NOADSTEST', false);
    const userwcbranch = _get(ctx, 'query.userwcbranch', undefined);

    return {
        featuresConfig: ctx.data.featuresConfig,
        isBot: ctx.data.isBot,
        ATC_ID: ctx.data.ATC_ID,
        optimizelyDynamicConfig: ctx.data.optimizelyDynamicConfig,
        disableThirdparty,
        brand: ctx.data.brand,
        navS3Data: ctx.data.navS3Data,
        userwcbranch,
    };
};

const { BonnetAppComponent } = withBonnetApp(App, {
    reducers: [
        userDuck,
        inventoryDuck,
        savedInventoryDuck,
        recentSearchDuck,
        ownersDuck,
        expiredPageMessageDuck,
        authDuck,
        paymentsDuck,
        dealsDuck,
        brandDuck,
        birfDuck,
        currentPageNameDuck,
        globalConfigsDuck,
        dealerCrawlPathDuck,
        dealerFiltersDuck,
        dealerMetadataDuck,
        dealerPaginationDuck,
        dealerResultsDuck,
        dealerSortDuck,
        dealerUrlsDuck,
        dealerSetMakeDuck,
        dealerLotDuck,
        dealerLotResultsDuck,
        dealerSetPaidSearchIdDuck,
        inventoryActiveOwnerDuck,
        inventoryActiveInteractionDuck,
        inventoryFiltersDuck,
        inventoryMetadataDuck,
        inventoryPaginationDuck,
        inventoryResultsDuck,
        inventorySortDuck,
        inventoryUrlsDuck,
        inventorySupportMakesDuck,
        dealerSearchPathDuck,
        dealerTabsDuck,
        showContactDealerDuck,
        serviceDealerDuck,
        showInventoryDuck,
        dealerDetailOriginDuck,
        retainOwnerDuck,
        inventoryActiveEmailListingDuck,
        queryDuck,
        lscOwnerDuck,
    ],
    modules: [
        PersonalizationEngineModule,
    ],
});

export default BonnetAppComponent;
