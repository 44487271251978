import { createAction, stringDuckCreator } from '@atc/modular-redux';

export default function activeDuckCreator({
    namespace = '',
    store = 'active',
    storePath,
    initialState = null,
    ...rest
} = {}) {

    return stringDuckCreator({
        ...rest,
        initialState,
        namespace,
        store,
        storePath,
    }).extend({
        creators: ({ types }) => ({
            setActive: createAction(types.SET),
            removeActive: createAction(types.CLEAR),
        }),
    });
}
