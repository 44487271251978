const blackListKeys = [
    'keywordsfyd',
    'make',
    'makeCodes',
];

const blackListValues = [
    null,
    'null',
];

const isValidQueryParams = (queryParams = {}) => {
    for (const key in queryParams) {
        if (blackListKeys.includes(key) || blackListValues.includes(queryParams[key])) {
            return false;
        }
    }
    return true;
};

export default isValidQueryParams;
