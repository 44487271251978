const get = (obj, path, fallbackResult) => {
    let keys = [];
    if (Array.isArray(path)) {
        keys = path;
    } else if (typeof path !== 'object') {
        keys = path.toString().split('.');
    }

    let result = obj;

    for (const key of keys) {
        result = result && result[key];
    }

    return result !== undefined ? result : fallbackResult;
};

export default get;
