import { get as _get } from '../utilities/common';
import { pageNames } from '@atc/bonnet-paths';

import {
    dealerFiltersDuck,
    dealerPaginationDuck,
    inventoryFiltersDuck,
    userDuck,
    dealerSetMakeDuck,
    inventoryPaginationDuck,
    inventorySortDuck,
    inventoryActiveOwnerDuck,
} from '../ducks';

const getters = {
    [pageNames.DEALER_SEARCH]: (ctx) => {
        const state = ctx.store.getState();

        // Remove any keys from the query that will be replaced so that deselected values are not retained
        Object.keys(dealerFiltersDuck.selectors.getFiltersOptions(state)).forEach((key) => delete ctx.query[key]);
        delete ctx.query.sortBy;
        delete ctx.query.numRecords;
        delete ctx.query.firstRecord;

        const pagination = dealerPaginationDuck.selectors.getDuckState(state);
        const makeCode = dealerSetMakeDuck.selectors.getDuckState(state);

        pagination.numRecords = pagination?.numRecords === 24 ? 25 : pagination.numRecords;
        delete ctx.query.make;
        ctx.query = {
            ...ctx.query,
            // we must get city, state, zip first from user reducer
            ...userDuck.selectors.getLocation(state),
            // as request values maybe updating it from the filters
            ...dealerFiltersDuck.selectors.getRequestValues(state),
            make: makeCode,
            numRecords: pagination.numRecords || 25,
            firstRecord: (pagination.currentPage - 1) * pagination.numRecords || 0,
        };
    },

    [pageNames.DEALER_DETAILS]: (ctx) => {
        const state = ctx.store.getState();
        // Remove any keys from the query that  be replaced so that deselected values are not retained
        Object.keys(inventoryFiltersDuck.selectors.getFiltersOptions(state)).forEach((key) => {
            // do not remove query if startYear and endYear has value
            if (key === 'startYear' || key === 'endYear') {
                if (!ctx.query[key]) {
                    delete ctx.query[key];
                }
            } else {
                delete ctx.query[key];
            }
        });
        delete ctx.query.sortBy;
        delete ctx.query.numRecords;
        delete ctx.query.firstRecord;

        const pagination = inventoryPaginationDuck.selectors.getDuckState(state);
        const sortBy = inventorySortDuck.selectors.getDuckState(state);
        const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);

        // tabIndex currently is not value
        // const { pagination, sort: sortBy, activeOwner, tabIndex } = _get(state, ['domain', pageNames.DEALER_DETAILS], {});
        const { id: dealerId, location = {} } = _get(state, ['owners', activeOwner]);
        pagination.numRecords = pagination?.numRecords === 24 ? 25 : pagination.numRecords;

        // use the city state zip of the dealership not the user
        const { city, state: _state, zip } = _get(location, 'address', {});

        ctx.query = {
            ...ctx.query,
            ...inventoryFiltersDuck.selectors.getRequestValues(state),
            dealerId,
            sortBy,
            numRecords: pagination.numRecords || 25,
            firstRecord: (pagination.currentPage - 1) * pagination.numRecords || 0,
            city,
            state: _state,
            zip,
            // tabIndex,
        };
    },
};

export default function getFindDealerQuery() {
    return async (ctx) => getters[ctx.pageName](ctx);
}
