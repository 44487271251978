const set = (obj, path, value) => {
    let keys = [];
    if (Array.isArray(path)) {
        keys = path;
    } else if (typeof path !== 'object') {
        keys = path.toString().split('.');
    }

    const lastKey = keys.pop();
    let current = obj;

    for (const key of keys) {
        if (!current[key]) {
            current[key] = {};
        }
        current = current[key];
    }

    current[lastKey] = value;
};

export default set;
