import { get as _get } from './common';

export default function getDynamicSpecifications(filtersValues, listing) {
    // Display dynamic Specifications based on Filter Value
    const selectedvehicleStyles = _get(filtersValues, 'vehicleStyleCodes.value', '') || _get(filtersValues, 'vehicleStyleCode.value', []);
    const selectedFuelTypeGroups = _get(filtersValues, 'fuelTypeGroup', []);
    const listingBodyStyle = listing?.bodyStyleCodes?.[0] || listing?.bodyStyles?.[0]?.code;
    const bodyStyle = listing?.style?.[0] || listing?.bodyStyles?.[0]?.name;
    const listingFuelType = listing?.fuelType;

    let dynamicSpecifications = [];

    const getFuelTypeData = () => {
        const fuelTypeData = [];
        if ((['Electric', 'Hybrid Gas/Electric'].includes(listingFuelType) || ['E', 'B'].includes(listingFuelType?.code))) {
            if (listing?.specifications?.mpg?.value) {
                const mpg = listing.specifications.mpg.value;
                fuelTypeData.push(mpg);
            } else if (listing?.specifications?.mileRange?.value) {
                const mileRange = listing.specifications.mileRange.value;
                fuelTypeData.push(mileRange);
            }
        }

        if ((listingFuelType === 'Gasoline' || listingFuelType?.code === 'G') && listing?.specifications?.mpg?.value) {
            const mpg = listing.specifications.mpg.value;
            fuelTypeData.push(mpg);
        }
        return fuelTypeData;
    };

    const bodySubTypeName = listing?.styleSubtype?.[0] || listing?.bodyStyleSubType?.[0]?.name;

    if (selectedvehicleStyles.length > 0 || selectedFuelTypeGroups.length > 0) {
        if (listingBodyStyle === 'TRUCKS') {
            // Display Format: bodystylesubtype/truckCabSize | Bed Length | Fuel Type Data
            const truckSpecifications = [];
            if (bodySubTypeName && bodyStyle) {
                truckSpecifications.push(`${bodySubTypeName} ${bodyStyle}`);
            }
            if (listing?.truckBedLength) {
                const bedLength = listing.truckBedLength;
                truckSpecifications.push(`${bedLength} Bed Length`);
            }
            dynamicSpecifications = truckSpecifications;
        }

        if (listingBodyStyle === 'CONVERT') {
            // Display Format: {# of Cylinder + Engine Displacement} * Drive Type * Fuel Type Data
            let cylinders;
            let engineDisplacement;
            const engineSpecs = [];
            const convertibleSpecs = [];

            if (listing?.displacementUOM) {
                engineDisplacement = listing.displacementUOM;
                engineSpecs.push(`${engineDisplacement}L `);
            }
            if (listing?.specifications?.engine?.value) {
                cylinders = listing.specifications.engine.value;
                engineSpecs.push(`${cylinders} ${bodyStyle}`);
            }
            if (engineSpecs.length > 0) {
                convertibleSpecs.push(engineSpecs);
            }

            if (listing?.specifications?.driveType?.value) {
                const driveType = listing.specifications.driveType.value;
                convertibleSpecs.push(driveType);
            }
            dynamicSpecifications = convertibleSpecs;
        }

        if (['VANS', 'SEDAN', 'SUV'].includes(listingBodyStyle)) {
            // Display Format: bodystylesubtype | fuel type
            // only display these body styles subtypes
            const listBodyStyleSubTypes = ['Cargo', 'Compact', 'Subcompact', 'Midsize', 'Fullsize'];
            const styleSpec = [];
            if (bodySubTypeName) {
                const bodyStyleSubType = listing.styleSubtype;
                const fullSizeVan = bodyStyle === 'Van' && bodyStyleSubType === 'Fullsize';
                // if its a van and (minivan/fullsize) append passenger
                if (!fullSizeVan && listBodyStyleSubTypes.includes(bodySubTypeName) && bodyStyle) {
                    styleSpec.push(`${bodySubTypeName} ${bodyStyle}`);
                }
                // Display Minivan without bodystyle(Van) to prevent duplication (Minivan Van)
                if (bodySubTypeName === 'Minivan') {
                    styleSpec.push(`Passenger ${bodySubTypeName}`);
                }
                if (fullSizeVan && bodyStyle) {
                    styleSpec.push(`Passenger ${bodySubTypeName} ${bodyStyle}`);
                }
            }
            dynamicSpecifications = styleSpec;
        }
        if ((listingBodyStyle === 'HATCH' || listingBodyStyle === 'COUPE')) {
            // Door | FuelType
            const hatchSpec = [];
            if (listing?.doors) {
                const { doors } = listing;
                hatchSpec.push(`${doors} Door ${bodyStyle}`);
            }
            dynamicSpecifications = hatchSpec;
        }

        const fuelTypeData = getFuelTypeData();
        if (fuelTypeData.length > 0) {
            dynamicSpecifications.push(...fuelTypeData);
        }
    }
    return dynamicSpecifications;
}
