/* eslint-disable linebreak-style */
/* eslint-disable indent */

const { fetchJSON } = require('@bonnet/next/fetch');

export default async function getValidSeoMakesFromSiteMap() {
    let seoMakesFromSiteMap = null;
    try {
        seoMakesFromSiteMap = await fetchJSON('/car-dealers/sitemaps/kbb_make_dealers.json');
    } catch (error) {
        return {};
    }
    const validSeoMakesFromSiteMap = [];
    if (JSON.stringify(seoMakesFromSiteMap) !== '{}' && seoMakesFromSiteMap.urls.length > 0) {
        seoMakesFromSiteMap.urls.forEach((url) => {
          const data = url.split('/').filter((part) => part !== '');
          const make = data[data.length - 1];

          validSeoMakesFromSiteMap.push(make);
        });
    }
    return validSeoMakesFromSiteMap;
}
