import { fetchJSON } from '@bonnet/next/fetch';

export default async function fetchMakesFromSyndication(data) {
    let result = [];

    try {
        const response = await fetchJSON('/car-dealers/syndication/makelist', {
            data,
            circuitBreaker: {
                id: 'syndication_makelist',
                timeout: 3000,
                fallback: { items: [] },
            },
        });

        result = response.items;
    } catch (error) {
        console.log('fetchMakesFromSyndication error', error);
    }

    return result;
}
