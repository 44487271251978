import memoryCache from '@atc/bonnet-reference-cache';

async function fetchWithCache(fetch, fetchParam, key, ttl = 60000) {
    try {
        const value = memoryCache.get(key);
        if (value) {
            return value;
        }
        const data = await fetch(fetchParam);
        memoryCache.put(key, data, { ttl });
        return data;
    } catch (error) {
        console.log('fetchWithCache error', error);
    }
}

export default fetchWithCache;
