import { getKbbGlobalNavConfiguration } from '@atc/bonnet-reference';
import { brands } from 'reaxl-brand';
import { fetchWithCache } from '../utilities';

export default function withKbbNavFromS3() {
    return async (ctx) => {
        const { brand } = ctx.data;
        const isKbb = brand === brands.KBB_BRAND;
        if (isKbb && !ctx.data.navS3Data && typeof window === 'undefined') {
            try {
                const navigation = await fetchWithCache(
                    getKbbGlobalNavConfiguration,
                    null,
                    'nav-s3-data',
                    3600000 // 1 hour
                );

                if (navigation) {
                    ctx.data.navS3Data = navigation;
                } else {
                    console.error('Error fetching kbb global navigation configuration - no data found');
                }
            } catch (error) {
                console.log('withKbbNavFromS3 - ', error);
            }
        }
    };
}
