import { merge as _merge } from '../utilities/common';

async function getFeaturesData({ environment, ctx }) {

    const { experience } = ctx.query;
    const { brand } = ctx.data;

    // Load the features from the local files
    const { default: prodFeatures } = await import(`../features/prod/${brand}/${experience ?? brand}.js`);

    let qaFeatures = {};
    if (environment === 'qa') {
        ({ default: qaFeatures } = await import(`../features/qa/${brand}/${experience ?? brand}.js`));
    }

    // merging features because qa needs some prod features
    return _merge(prodFeatures, qaFeatures);
}

export default function withFeatures({ environment = 'prod' } = {}) {
    return async (ctx) => {

        try {
            if (typeof window === 'undefined') {

                // SERVER SIDE FEATURES

                ctx.data.featuresConfig = {
                    features: await getFeaturesData({ environment, ctx }),
                    lastUpdated: Date.now(),
                };
            } else {

                // CLIENT SIDE FEATURES

                // Features are currently only updatable if either the BRAND or EXPERIENCE changes
                // BRAND is only updatable with a FULL PAGE refresh so the server side features definition handles that
                // EXPERIENCE is updatable on the client.  At this time we only request new features if we compare
                // the next experience value against the previous experience
                // using the value stored in redux as it is persisted between page loads and available on client side getInitialProps runs
                const { query: prevQuery } = ctx.store.getState();
                const { experience: prevExperience } = prevQuery;
                const nextExperience = ctx.query.experience;

                if (prevExperience !== nextExperience) {
                    ctx.data.featuresConfig = {
                        features: await getFeaturesData({ environment, ctx }),
                        lastUpdated: Date.now(),
                    };
                    return;
                }

                // If we are using the same experience then reuse the initial features config from the NEXT_DATA object
                ctx.data.featuresConfig = window.__NEXT_DATA__.props.featuresConfig;
            }

        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('withFeatures - ', error);
        }
    };
}
