/* eslint-disable import/prefer-default-export */
export const unusedKBBMakes = [
    {
        makeName: 'Tesla',
        disabled: true,
    },
    {
        makeName: 'Lucid',
        disabled: true,
    },
    {
        makeName: 'VinFast',
        disabled: true,
    },
    {
        makeName: 'DeLorean',
        disabled: true,
    },
    {
        makeName: 'Rivian',
        disabled: true,
    },
    {
        makeName: 'AMC',
        disabled: true,
    },
    {
        makeName: 'Avanti',
        disabled: true,
    },
    {
        makeName: 'Daewoo',
        disabled: true,
    },
    {
        makeName: 'Datsun',
        disabled: true,
    },
    {
        makeName: 'Daihatsu',
        disabled: true,
    },
    {
        makeName: 'Eagle',
        disabled: true,
    },
    {
        makeName: 'Fisker',
        disabled: true,
    },
    {
        makeName: 'Freightliner',
        disabled: true,
    },
    {
        makeName: 'Geo',
        disabled: true,
    },
    {
        makeName: 'Isuzu',
        disabled: true,
    },
    {
        makeName: 'KARMA',
        disabled: true,
    },
    {
        makeName: 'Lancia',
        disabled: true,
    },
    {
        makeName: 'Lotus',
        disabled: true,
    },
    {
        makeName: 'Maybach',
        disabled: true,
    },
    {
        makeName: 'Mercury',
        disabled: true,
    },
    {
        makeName: 'Merkur',
        disabled: true,
    },
    {
        makeName: 'Oldsmobile',
        disabled: true,
    },
    {
        makeName: 'Peugeot',
        disabled: true,
    },
    {
        makeName: 'Plymouth',
        disabled: true,
    },
    {
        makeName: 'Renault',
        disabled: true,
    },
    {
        makeName: 'SRT',
        disabled: true,
    },
    {
        makeName: 'Saab',
        disabled: true,
    },
    {
        makeName: 'Saturn',
        disabled: true,
    },
    {
        makeName: 'Scion',
        disabled: true,
    },
    {
        makeName: 'Smart',
        disabled: true,
    },
    {
        makeName: 'Sterling',
        disabled: true,
    },
    {
        makeName: 'Suzuki',
        disabled: true,
    },
    {
        makeName: 'Triumph',
        disabled: true,
    },
    {
        makeName: 'Yugo',
        disabled: true,
    },
    {
        makeName: 'Polestar',
        disabled: true,
    },
    {
        makeName: 'Pontiac',
        disabled: true,
    },
    {
        makeName: 'Hummer',
        disabled: true,
    },
];
export const breadcrumbsForFLD = (isKbb) => [
    {
        label: 'Home',
        href: '/',
    },
    {
        label: isKbb ? 'Dealers' : 'Car Dealers',
        href: '',
    },
];
export const ANALYTICS_PAGE_NAME = {
    DDP: 'ddp',
};

export const defaultZipCode = '92618';
export const kbbBrand = 'kbb';
export const atcBrand = 'atc';
export const previewAtcBrand = 'previewAtc';
export const phoneNumberLabel = 'Phone number';
export const allMakeValue = 'all';
export const makeQuery = '&make=';
export const facebookAppIdKBB = '184365531583362';
export const titleKbb = 'Kelley Blue Book';
export const titleAtc = 'Autotrader';
export const kbbUrl = 'https://www.kbb.com';
export const kbbHostUrl = process.env.KBB_HOST ?? 'https://www.kbb.com';
export const logoKbb = 'https://file.kbb.com/kbb/images/m1/kbblogo.v1.png';
export const signedInCookieName = 'MyConsumerSignedIn';
export const dealerRatingPageType = 'dealer';
export const PAGE_NAME = {
    FYD: 'fyd_srl',
    DDP: 'fyd_ddp',
};
export const TAB_NAME = {
    SALES: 'sales',
    REVIEWS: 'reviews',
    ABOUT: 'about',
    SERVICE: 'service',
};
export const TAB_ANALYTICS_NAME = {
    sales: 'ddp_sales',
    reviews: 'ddp_review',
    about: 'ddp_about',
    service: 'ddp_service',
};
export const ATC_TAB_ORDER = [
    TAB_NAME.SALES,
    TAB_NAME.REVIEWS,
    TAB_NAME.SERVICE,
    TAB_NAME.ABOUT,
];
export const KBB_TAB_ORDER = [
    TAB_NAME.REVIEWS,
    TAB_NAME.SALES,
    TAB_NAME.SERVICE,
    TAB_NAME.ABOUT,
];

export const EMAIL_SUBJECT = {
    REQUEST_A_TEST_DRIVE: { value: 'Request a Test Drive', text: 'Request a Test Drive', label: 'request-a-test-drive' },
    LOCAL_HOME_DELIVERY: { value: 'Local Home Delivery', text: 'Local Home Delivery', label: 'local-home-delivery' },
    TRADE_IN_INFO: { value: 'Trade-In Info', text: 'Trade-In Info', label: 'trade-in-info' },
    AVAILABILITY_OF_VEHICLE: { value: 'Availability of Vehicle', text: 'Availability of Vehicle', label: 'availability-of-vehicle' },
    GENERAL_VEHICLE_INFO: { value: 'General Vehicle Info', text: 'General Vehicle Info', label: 'general-vehicle-info' },
    VEHICLE_SERVICE_AND_REPAIR: { value: 'Vehicle Service and Repair', text: 'Vehicle Service and Repair', label: 'vehicle-service-and-repair' },
};

export const EMAIL_SUBJECTS = [
    EMAIL_SUBJECT.REQUEST_A_TEST_DRIVE,
    EMAIL_SUBJECT.LOCAL_HOME_DELIVERY,
    EMAIL_SUBJECT.TRADE_IN_INFO,
    EMAIL_SUBJECT.AVAILABILITY_OF_VEHICLE,
    EMAIL_SUBJECT.GENERAL_VEHICLE_INFO,
    EMAIL_SUBJECT.VEHICLE_SERVICE_AND_REPAIR,
];

export const sameAs = [
    'https://www.facebook.com/kbb/',
    'https://twitter.com/KelleyBlueBook',
    'https://www.pinterest.com/kelleybluebook/',
    'https://www.linkedin.com/company/kelley-blue-book',
    'https://www.youtube.com/user/kbb',
    'https://www.instagram.com/kbb_com/',
];
export const breadcrumbsForFindDealer = [
    {
        label: 'Home',
        href: '/',
    },
    {
        label: 'Dealers',
        href: '',
    },
];

export const dealerDetailsTabs = [
    {
        heading: 'About',
        name: 'About container',
    },
    {
        heading: 'Reviews',
        name: 'Reviews container',
    },
    {
        heading: 'Sales',
        name: 'Sales container',
    },
    {
        heading: 'Services',
        name: 'Services container',
    },
];

export const servicesAndAmenitiesBlacklist = ['Good Credit', 'Bad Credit', 'No Credit'];

export const languages = [
    'Mandarin Chinese', 'Spanish', 'English', 'Hindi', 'Bengali', 'Portuguese', 'Russian', 'Japanese',
    'Western Punjabi', 'Marathi', 'Telugu', 'Wu Chinese', 'Turkish', 'Korean', 'French', 'German',
    'Vietnamese', 'Tamil', 'Yue Chinese', 'Urdu', 'Javanese', 'Italian', 'Egyptian Arabic', 'Gujarati',
    'Iranian Persian', 'Bhojpuri', 'Southern Min', 'Hakka', 'Jin Chinese', 'Hausa', 'Kannada', 'Indonesian',
    'Polish', 'Yoruba', 'Xiang Chinese', 'Malayalam', 'Odia', 'Maithili', 'Burmese', 'Eastern Punjabi',
    'Sunda', 'Sudanese Arabic', 'Algerian Arabic', 'Moroccan Arabic', 'Ukrainian', 'Igbo', 'Northern Uzbek',
    'Sindhi', 'North Levantine Arabic', 'Romanian', 'Tagalog', 'Dutch', 'Saʽidi Arabic', 'Gan Chinese',
    'Amharic', 'Northern Pashto', 'Magahi', 'Thai', 'Saraiki', 'Khmer', 'Chhattisgarhi', 'Somali',
    'Malaysian', 'Cebuano', 'Nepali', 'Mesopotamian Arabic', 'Assamese', 'Sinhalese', 'Northern Kurdish',
    'Hejazi Arabic', 'Nigerian Fulfulde', 'Bavarian', 'South Azerbaijani', 'Greek', 'Chittagonian',
    'Kazakh', 'Deccan', 'Hungarian', 'Kinyarwanda', 'Zulu', 'South Levantine Arabic', 'Tunisian Arabic',
    'Sanaani Spoken Arabic', 'Northern Min', 'Southern Pashto', 'Rundi', 'Czech', 'Taʽizzi-Adeni Arabic',
    'Uyghur', 'Eastern Min', 'Sylheti', 'Chinese',
];

export const defaultImage = [{ src: 'https://file.kbb.com/kbb/images/icons/no-image-te/420x278-new.png', alt: 'defaultImage' }];

export const chatProvider = {
    providers: ['CONTACT_AT_ONCE', 'GUBAGOO', 'LIVE_PERSON', 'ACTIVENGAGE'],
    gubagooBaseUrl: 'https://cdn.gubagoo.io/toolbars/integration/AT_',
};

export const LITTLE_GUY_WEBCOMPONENT_FEATURE = 'littleguy_webcomponent';

export const dealerlistQuery = ['zip', 'city', 'state', 'brand', 'make', 'makeCode'];

export const dealerDetailQuery = ['dealerId', 'dealerdetailstabs', 'startYear', 'engineCode',
    'extColorSimple', 'doorCode', 'listingType', 'fuelTypeGroup', 'intColorSimple', 'listingFeature',
    'modelCode', 'trimCode', 'mileage', 'dealType', 'seriesCode', 'transmissionCode', 'mpgRange',
];

export const ddpClickType = {
    DEALER_CARD_CLICK: 'DEALER_CARD_CLICK',
    VIEW_CAR_CLICK: 'VIEW_CAR_CLICK',
    SCHEDULE_CLICK: 'SCHEDULE_CLICK',
};

export const ddpContactType = {
    LISTING_FORM: 'FYC',
    CONTACT_DEALER_FORM: 'FYD',
};

export const defaultRenderItemsNumber = 10;
export const dynamicEmbeddedWidgetExpanderHeightValue = 250;
