import { decode } from '@atc/bonnet-seo';
import { fetchSeoLocation } from '../fetchers';
import { dealerlistQuery, kbbBrand } from '../constants';
import { getPath, transformsMake, filterDealerQuery } from '../utilities';
import getValidLocationsFromSiteMap from '../utilities/getValidLocationsFromSiteMap';

export default function getSeoLocations() {
    return async (ctx) => {

        let locations = await fetchSeoLocation({
            query: {
                state: ctx.query.state,
            },
        });

        const isKbb = (ctx.data.brand === kbbBrand);
        const validLocations = await getValidLocationsFromSiteMap(isKbb);
        if (validLocations.length !== 0) {
            locations.filter((location) => {
                const locationSplit = location.value.split(/[+-]/);
                const cityOld = locationSplit[0]?.toLowerCase();
                const stateOld = locationSplit[1]?.toLowerCase();
                const locationOld = `${cityOld}-${stateOld}`;
                return validLocations.includes(locationOld);
            });
        } else {
            locations = [];
        }

        const data = await Promise.all(locations.map(async ({ value, label }) => {
            const location = await decode(value, { param: 'location' });

            // remove unwanted parameters from the existing query string
            // eslint-disable-next-line no-unused-vars
            const { numRecords, firstRecord, sortBy, makeCode, dma, searchRadius, location: queryLocation, make, ...query } = ctx.query;
            const seoMakeCode = transformsMake(make?.toLowerCase(), true) ?? '';

            // Build a query object for the new url
            const _query = {
                ...query,
                ...location,
                seoMakeCode,
            };

            delete _query.make;
            delete _query.zip;

            const href = await getPath(ctx.pageName, filterDealerQuery(_query, dealerlistQuery), { brand: ctx.data.brand });
            return {
                href,
                label: label.replace(/\+/g, ' '),
            };
        }));

        // update the context data object with the response
        Object.assign(ctx.data, { seoLocations: data });
    };
}
